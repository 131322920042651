import Header from "./header/header";
import NavBar from "./nav/nav-bar";
import Footer from "./footer/footer";
import { useHistory, useParams } from "react-router";
import { useEffect, useState, useRef } from "react";
import Service from "./webservice/http";
import ClipLoader from "react-spinners/ClipLoader";
import { BiError, BiCommentAdd, BiTaskX } from "react-icons/bi";
import { RiSignalWifiErrorFill, RiDeleteRow } from "react-icons/ri";

const LeadDetails = () => {
  const { leadID } = useParams();

  const [leadDetail, setLeadDetail] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const services = new Service();
  const history = useHistory();

  const [statusUnqualified, setStatusUnqualified] = useState(null);
  const [statusNew, setStatusNew] = useState(null);
  const [statusWorking, setStatusWorking] = useState(null);
  const [statusConverted, setStatusConverted] = useState(null);
  const [statusCompleted, setStatusCompleted] = useState(null);
  const [activetab, setActivetab] = useState("call");

  const [edit, setEdit] = useState(false);

  /**
   * Fetch current status of a specific opportunity
   */

  function fetchData() {
    services.get(`api/call/lead/${leadID}/details/`).then((res) => {
      // console.log(res);
      setIsPending(false);
      if (res == "TypeError: Failed to fetch") {
        setError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }

          if (res.status !== "") {
            if (res.status === "Unqualified") {
              setStatusUnqualified("active");
            } else {
              setStatusUnqualified("step-active");
              if (res.status === "New") {
                setStatusNew("active");
              } else {
                setStatusNew("step-active");
                if (res.status === "Working") {
                  setStatusWorking("active");
                } else {
                  setStatusWorking("step-active");
                  if (res.status === "Converted") {
                    setStatusConverted("active");
                  } else {
                    setStatusConverted("step-active");
                    if (res.status === "Mark as Completed") {
                      setStatusCompleted("active");
                    } else {
                      setStatusUnqualified(null);
                      setStatusNew(null);
                      setStatusWorking(null);
                      setStatusConverted(null);
                      setStatusCompleted(null);
                    }
                  }
                }
              }
            }
          }

          setError(null);
          setLeadDetail(res);
        } catch (e) {
          setError(e);
        }
      }
    });
  }

  useEffect(() => {
    setLeadDetail(null);
    setIsPending(true);
    setError(null);
    fetchData();
  }, []);

  console.log("hyhy", leadDetail);
  const tasks = [
    "Complete the project report",
    "Attend the team meeting",
    "Submit the expense report",
    "Prepare presentation slides",
    "Review the marketing plan",
    "Follow up with clients",
  ];

  const [taskdata, setTaskdata] = useState(tasks);
  const [newtask, setNewtask] = useState("");

  const addNotes = async () => {
    try {
      const res = await services.post(`api/call/lead/${leadID}/details/`, {
        task: newtask,
      });
      console.log(res);
      if (res === "TypeError: Failed to fetch") {
        setError("Failed to Fetch");
      } else {
        setNewtask("");
        fetchData();
      }
    } catch (error) {
      setError("Failed to Fetch");
    }
  };

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />

      <div className="container-fluid">
        <div className="my-call">
          <div className="my-calls-column">
            <div className="calls-top-pannel">
              <div className="row">
                <div className="col-md-6">
                  <div className="pannel-heading clearfix">
                    <div className="pannel-heading-icon">
                      <i className="flaticon-incoming-call"></i>
                    </div>
                    <div className="pannel-heading-info">
                      {localStorage.getItem("usecase") ===
                      "Third Party Collection Agency" ? (
                        <p>Promise To Pay </p>
                      ) : (
                        <p>Opportunity </p>
                      )}

                      <h3>
                        {" "}
                        Analysis <i className="icon-down-arrow-round"></i>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pannel-nav panel-nav-right clearfix">
                    <ul className="clearfix">
                      <li>
                        <a href="#" data-toggle="modal" data-target="#New">
                          Follow{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">Edit </a>
                      </li>
                      <li>
                        <a href="#">Delete </a>
                      </li>
                      <li>
                        <a href="#">Clone </a>
                      </li>
                    </ul>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {leadDetail && (
          <>
            <div className="my-call">
              <div className="my-calls-column">
                <div className="source-details clearfix">
                  <div className="source-arrow">
                    <i className="icon-down-arrow-round"></i>
                  </div>
                  <div className="source-steps">
                    <ul className="clearfix">
                      <li className={statusUnqualified}>
                        <a href="#">Unqualified </a>
                      </li>
                      {localStorage.getItem("usecase") ===
                      "Augmenting Digital Reach" ? (
                        <li className={statusNew}>
                          <a href="#">Qualified</a>
                        </li>
                      ) : (
                        <li className={statusNew}>
                          <a href="#">New </a>
                        </li>
                      )}

                      {localStorage.getItem("usecase") ===
                      "Augmenting Digital Reach" ? (
                        <li className={statusWorking}>
                          <a href="#">In - progress</a>
                        </li>
                      ) : (
                        <li className={statusWorking}>
                          <a href="#">Working </a>
                        </li>
                      )}
                      {localStorage.getItem("usecase") ===
                      "Augmenting Digital Reach" ? (
                        <li className={statusConverted}>
                          <a href="#">Status</a>
                        </li>
                      ) : (
                        <li className={statusConverted}>
                          <a href="#">Converted</a>
                        </li>
                      )}

                      <li className={statusCompleted}>
                        <a href="#">Mark Status As Completed</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="key-source">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="key-source-col">
                        <h4>Key Fields</h4>
                        <p className="line" style={{ fontSize: "16px" }}>
                          Opportunity Source :{" "}
                          {leadDetail?.source[0].toUpperCase() +
                            leadDetail?.source.slice(
                              1,
                              leadDetail?.source.length
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="key-source-col">
                        <h4>Guidance For Success</h4>
                        {localStorage.getItem("usecase") ===
                        "Augmenting Digital Reach" ? (
                          <h5>Keep track of your Lead Status</h5>
                        ) : (
                          <h5>Keep Track of Info of your unqualified leads</h5>
                        )}

                        {localStorage.getItem("usecase") ===
                        "Augmenting Digital Reach" ? (
                          <p>
                            Your Opportunity may be unqualified if their details
                            are not confirmed.
                          </p>
                        ) : (
                          <p>
                            Your lead may be unqualified if they are not
                            interested in your products or they have left the
                            company associated with the prospect.
                          </p>
                        )}

                        <ul>
                          {/* <li>Document lessons learned for future reference</li> */}
                          <li>Save outreach details and contact information</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lead-tab flex flex-row justify-between">
              <div className="row" style={{ width: "50%" }}>
                <div className="col-sm-12 pr-0">
                  <div className="my-call">
                    <div className="my-calls-column p-0">
                      <div className="tab_area">
                        <ul className="tabs01 clearfix">
                          <li>
                            <a href="#Activity">Activity</a>
                          </li>
                          {/* <li>
                            <a href="#Chatter">Chatter</a>
                          </li>
                          <li>
                            <a href="#Details">Details</a>
                          </li>
                          <li>
                            <a href="#News">News</a>
                          </li> */}
                        </ul>
                        <div className="tab_container">
                          <div id="Activity" className="tab_content">
                            <div className="tab-details pb-0">
                              <div className="tab-gray">
                                <ul className="clearfix tab-options ">
                                  <li
                                    className={`cursor-pointer ${
                                      activetab === "call" ? "active" : ""
                                    }`}
                                    onClick={() => setActivetab("call")}
                                  >
                                    <a>Call Log </a>
                                  </li>
                                  <li
                                    onClick={() => setActivetab("task")}
                                    className={`cursor-pointer ${
                                      activetab === "task" ? "active" : ""
                                    }`}
                                  >
                                    <a> New Task</a>
                                  </li>
                                  {/* <li>
                                    <a> New Event</a>
                                  </li> */}
                                  <li
                                    className={`cursor-pointer ${
                                      activetab === "email" ? "active" : ""
                                    }`}
                                    onClick={() => setActivetab("email")}
                                  >
                                    <a>Email</a>
                                  </li>
                                  {localStorage.getItem("usecase") ===
                                  "Augmenting Digital Reach" ? (
                                    <li>
                                      <a>Status</a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {localStorage.getItem("usecase") ===
                                  "Augmenting Digital Reach" ? (
                                    <li
                                      className={`cursor-pointer ${
                                        activetab === "notes" ? "active" : ""
                                      }`}
                                      onClick={() => setActivetab("notes")}
                                    >
                                      <a>Notes</a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>

                                <div className="search-add clearfix">
                                  {activetab === "task" && (
                                    <>
                                      <div className="search-col">
                                        <input
                                          type="text"
                                          placeholder="Add Task..."
                                          value={newtask}
                                          onChange={(e) =>
                                            setNewtask(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="add" onClick={addNotes}>
                                        <a href="#">Add </a>
                                      </div>
                                    </>
                                  )}
                                  {activetab === "notes" && (
                                    <>
                                      <div className="search-col">
                                        <input
                                          type="text"
                                          placeholder="Add Notes..."
                                          value={newtask}
                                          // onChange={(e) =>
                                          //   setNewtask(e.target.value)
                                          // }
                                        />
                                      </div>
                                      <div
                                        className="add"
                                        // onClick={addNotes}
                                      >
                                        <a href="#">Add Notes </a>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="mt-4">
                                {activetab === "call" && (
                                  <div
                                    class="relative overflow-x-auto shadow-md sm:rounded-lg"
                                    style={{
                                      maxHeight: "120px",
                                      minHeight: "70px",
                                    }}
                                  >
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                      <thead
                                        class=" text-gray-700 bg-gray-50 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        <tr>
                                          <th scope="col" class="px-6 py-3">
                                            Call Id
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                            Date
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                            Customer
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                            Source
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                            Product
                                          </th>
                                          <th scope="col" class="px-6 py-3">
                                            Agent
                                          </th>
                                          {/* <th scope="col" class="px-6 py-3">
                                          Action
                                        </th> */}
                                        </tr>
                                      </thead>
                                      <tbody
                                        className=""
                                        style={{ fontSize: "13px" }}
                                      >
                                        {leadDetail?.calls.map((e) => (
                                          <tr class="bg-white border-b d">
                                            <td
                                              scope="row"
                                              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                            >
                                              {e?.call_id.slice(0, 6)}
                                            </td>
                                            <td
                                              scope="row"
                                              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                            >
                                              {e?.call_date}
                                            </td>
                                            <td class="px-6 py-4">
                                              {e?.customer}
                                            </td>
                                            <td class="px-6 py-4">
                                              {leadDetail?.source}
                                            </td>
                                            <td class="px-6 py-4">
                                              {e?.product}
                                            </td>
                                            <td class="px-6 py-4">
                                              {e?.agent}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {activetab === "task" && (
                                  <>
                                    {/* <div className=" flex justify-end mb-2">
                                      <button
                                        className="px-2 py-1 "
                                        style={{ border: "1px solid grey" }}
                                      >
                                        Pending
                                      </button>
                                      <button
                                        className="px-2 py-1"
                                        style={{ border: "1px solid grey" }}
                                      >
                                        Completed
                                      </button>
                                    </div> */}
                                    <div
                                      className=" bg-[#eef2f5] px-10 pt-5 pb-2"
                                      style={{
                                        maxHeight: "150px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      <ul>
                                        {leadDetail?.tasks?.map((e) => (
                                          <>
                                            <li
                                              className=" m-4"
                                              style={{ fontSize: "15px" }}
                                            >
                                              <input
                                                type="checkbox"
                                                // className="ml-2"
                                                style={{ marginRight: "5px" }}
                                              />{" "}
                                              {e}
                                            </li>
                                          </>
                                        ))}
                                        <li
                                          className=" list-disc m-4 opacity-50"
                                          style={{ fontSize: "15px" }}
                                        >
                                          Mail send for the promotional events
                                        </li>
                                        <li
                                          className=" list-disc m-4 opacity-50"
                                          style={{ fontSize: "15px" }}
                                        >
                                          Need KYC clarification
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                )}
                                {activetab === "notes" && (
                                  <div
                                    className=" bg-[#eef2f5] px-10 pt-5 pb-2"
                                    style={{
                                      maxHeight: "150px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <ul>
                                      {leadDetail?.tasks?.map((e) => (
                                        <li
                                          className=" list-disc m-4"
                                          style={{ fontSize: "15px" }}
                                        >
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {activetab === "email" && (
                                  <>
                                    <div
                                      className=" bg-[#eef2f5] px-10 pt-5 pb-8"
                                      style={{
                                        maxHeight: "150px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      <div
                                        className="flex justify-end mb-2"
                                        style={{ marginRight: "-20px" }}
                                      >
                                        <span
                                          onClick={() => setEdit(!edit)}
                                          className="cursor-pointer"
                                        >
                                          {edit ? (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-x-circle"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-pencil-square"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                              />
                                            </svg>
                                          )}
                                        </span>
                                      </div>
                                      {!edit ? (
                                        <span className="mb-4">
                                          I hope this message finds you well! I
                                          wanted to touch base regarding the
                                          home loan . If you have any updates or
                                          need assistance, please let me know.
                                          I’m here to help. Also, if you could
                                          confirm our meeting time for next
                                          week, that would be great. Looking
                                          forward to your reply!
                                        </span>
                                      ) : (
                                        <span className="mb-2">
                                          <textarea
                                            className="w-full p-4"
                                            style={{ height: "100px" }}
                                          >
                                            I hope this message finds you well!
                                            I wanted to touch base regarding the
                                            home loan . If you have any updates
                                            or need assistance, please let me
                                            know. I’m here to help. Also, if you
                                            could confirm our meeting time for
                                            next week, that would be great.
                                            Looking forward to your reply!
                                          </textarea>
                                        </span>
                                      )}
                                    </div>
                                    <div className="flex justify-end my-4">
                                      <button className="px-8 py-2 rounded-lg bg-blue-900 text-white font-semibold">
                                        {" "}
                                        Send
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* 
                          <div id="Chatter" className="tab_content">
                            <h2>Comming Soon</h2>
                          </div>
                          <div id="Details" className="tab_content">
                            <h2>Comming Soon</h2>
                          </div>
                          <div id="News" className="tab_content">
                            <h2>Comming Soon</h2>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="" style={{ width: "50%" }}>
                <div className="row">
                  <div className="col-sm-12 pr-0">
                    <div className="my-call">
                      <div className="my-calls-column p-0">
                        <div className="tab_area">
                          <ul className="tabs01 clearfix">
                            <div className="white-box-header">
                              {localStorage.getItem("usecase") ===
                              "Augmenting Digital Reach" ? (
                                <h3>Task Summary</h3>
                              ) : (
                                <h3>Call Summary</h3>
                              )}
                            </div>
                            {/* <li>
                            <a href="#Chatter">Chatter</a>
                          </li>
                          <li>
                            <a href="#Details">Details</a>
                          </li>
                          <li>
                            <a href="#News">News</a>
                          </li> */}
                          </ul>
                          <div className="tab_container ">
                            <div id="Activity" className="tab_content">
                              <div
                                className="overflow-y-auto"
                                style={{
                                  maxHeight: "259px",
                                  minHeight: "150px",
                                }}
                              >
                                {leadDetail?.calls.map((e) => (
                                  <div className="tab-gray m-4">
                                    <p>
                                      <span
                                        className=" font-extrabold"
                                        style={{ color: "#1A0059" }}
                                      >
                                        Call Id : {e?.call_id.slice(0, 6)}{" "}
                                      </span>
                                      <div className="mt-1">
                                        {e?.call_summary.summary}
                                      </div>
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <div
                                className=" flex justify-end mt-4"
                                style={{ fontSize: "12px" }}
                              >
                                * Powered by
                                <span className=" text-red-800 ml-2">
                                  {" "}
                                  Dataklout Gen Ai
                                </span>
                              </div>
                            </div>
                            {/* 
                          <div id="Chatter" className="tab_content">
                            <h2>Comming Soon</h2>
                          </div>
                          <div id="Details" className="tab_content">
                            <h2>Comming Soon</h2>
                          </div>
                          <div id="News" className="tab_content">
                            <h2>Comming Soon</h2>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isPending && (
          <div className="empty-call">
            <ClipLoader color="#2056FF" size="50px" />
          </div>
        )}

        {error && (
          <div className="empty-call">
            <p style={{ fontSize: "25px", color: "#FF8520" }}>
              {error === "Connection Error" && <RiSignalWifiErrorFill />}
              {error !== "Connection Error" && <BiError />}
              {error}
            </p>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default LeadDetails;
