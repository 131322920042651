import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import NavBar from "../nav/nav-bar";
import ReportNav from "./report-nav";
import { useState } from "react";
// import axios from "axios";
import Service from "../webservice/http";
import { useEffect } from "react";
import ForexViewmore from "./forexViewmore";

function Forex() {
  const [open, setOpen] = useState(false);
  // const [open1, setopen1] = useState(false);

  const [data, setData] = useState();
  const [error, setError] = useState();
  const services = new Service();
  const [id, setid] = useState("");
  function fetchData() {
    services.get("api/product/forex_list/").then((res) => {
      // console.log(res);
      setData(res);
    });
  }
  // console.log(data);
  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  const styles = {
    redIcon: {
      float: "inherit",
      color: "red",
    },
    greenIcon: {
      float: "inherit",
      color: "green",
    },
    alignTextCenter: {
      textAlign: "center",
    },
  };
  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div className="side-panel-with-table clearfix">
              <ReportNav />
              <div className="call-table">
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Forex </h3>
                            <p>Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="ss">
                  <thead>
                    <tr>
                      <th>
                        <p>Name</p>
                      </th>
                      <th>
                        <p>Contract Number</p>
                      </th>
                      {/* <th>
                        <p>Product</p>
                      </th> */}
                      <th>
                        <p>Buy</p>
                      </th>
                      <th>
                        <p>Sell</p>
                      </th>
                      <th>
                        <p>Quantity</p>
                      </th>

                      <th>
                        <p>Final Price </p>
                      </th>

                      <th>
                        <p>Amount</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((e, i) => (
                      <tr
                        onClick={() => {
                          setOpen(true);
                          setid(e?.id);
                        }}
                        className="p-up"
                        key={i}
                      >
                        <td>
                          <p className="blue">{e?.name}</p>
                        </td>
                        <td>
                          <p>{e?.contract_number}</p>
                        </td>
                        {/* <td>
                        <p className="blue">Forex</p>
                      </td> */}
                        <td>
                          <p>{e?.buy}</p>
                        </td>
                        <td style={{ alignItems: "center" }}>
                          <p>{e?.sell}</p>
                        </td>
                        <td>
                          <p>{e?.quantity}</p>
                        </td>
                        <td>
                          <p>{e?.fianl_price}</p>
                        </td>
                        <td
                          style={{ alignItems: "center", whiteSpace: "nowrap" }}
                        >
                          <p>{e?.amount}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {open && <ForexViewmore orderId={id} setOpen={setOpen} />}
    </div>
  );
}

export default Forex;
