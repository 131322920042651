import React from "react";
import { useState, useEffect } from "react";
import Service from "../webservice/http";

function MutualViewMore({ orderId, setOpen }) {
  const [data, setdata] = useState();
  const services = new Service();
  //   console.log(orderId);

  function fetchData() {
    services.get(`api/product/${orderId}/mutualfund_list/`).then((res) => {
      // console.log(res);
      setdata(res);
    });
  }
  console.log(data);
  useEffect(() => {
    fetchData();
  }, [orderId]);
  return (
    <div className="modal my-calls-popup show">
      <div className="backdrop"></div>
      <div className="modal-dialog " role="document" id="m-pop">
        <div className="my-calls-popup-details">
          <h2>Mutual Funds Details</h2>
          <div className="my-calls-form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Name :</label>
                  <span id="p-detail">{data.name}</span>
                  <br />
                </div>
                <div className="form-col clearfix">
                  <label>Company :</label>
                  <span id="p-detail">{data?.company_name}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Folio Number :</label>
                  <span id="p-detail">{data?.folio_number}</span>
                </div>

                <div className="form-col clearfix">
                  <label>Units :</label>
                  <span id="p-detail">{data?.units}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Buy :</label>
                  <span id="p-detail">{data?.buy}</span>
                </div>

                <div className="form-col clearfix">
                  <label>Date :</label>
                  <span id="p-detail">{data?.date}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Sell :</label>
                  <span id="p-detail">{data?.sell}</span>
                </div>

                <div className="form-col clearfix">
                  <label>Amount :</label>
                  <span id="p-detail">{data?.amount}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="border"></div>
          <div className="popup-footer">
            <>
              {/* <button className="btn" data-dismiss="modal" type="button">
                {" "}
                Edit
              </button> */}
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="btn"
                data-dismiss="modal"
                type="button"
              >
                Close
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MutualViewMore;
