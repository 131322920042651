import Header from "./header/header";
import NavBar from "./nav/nav-bar";
import Footer from "./footer/footer";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Service from "./webservice/http";
import ClipLoader from "react-spinners/ClipLoader";
import { BiError, BiCommentAdd, BiTaskX } from "react-icons/bi";
import { RiSignalWifiErrorFill, RiDeleteRow } from "react-icons/ri";
import Filter from "./filter";
import { FaFilter } from "react-icons/fa";

const LeadList = () => {
  const history = useHistory();

  const [leadList, setleadList] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const services = new Service();

  /**
   * Fetch opportunity list
   */

  function fetchData() {
    services.get("api/call/leads/").then((res) => {
      // console.log(res);
      setIsPending(false);
      if (res == "TypeError: Failed to fetch") {
        setError("Connection Error");
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          setleadList(res);
          setError(null);
        } catch (e) {
          setError(e);
        }
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [sideBarWidth, setSideBarWidth] = useState(0);
  const changeSideBarWidth = () => {
    if (sideBarWidth == 570) {
      setSideBarWidth(0);
      setIsFilterOpen(false);
    } else {
      setSideBarWidth(570);
      setIsFilterOpen(true);
    }
  };

  const filterHasChanged = () => {
    fetchData();
  };

  useEffect(() => {
    if (isFilterOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isFilterOpen]);

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />

      <a
        onClick={changeSideBarWidth}
        style={{
          position: "fixed",
          transition: ".5s",
          top: "30%",
          right: sideBarWidth,
          backgroundColor: "#F99E52",
          color: "white",
          borderTopColor: "black",
          borderWidth: "2px",
          boxShadow: "6px 6px 3px #999",
          paddingTop: "15px",
          paddingLeft: "5px",
          paddingRight: "5px",
          height: "45px",
          zIndex: "2",
        }}
      >
        <FaFilter size="20" />
      </a>
      <Filter
        width={sideBarWidth}
        changeSideBarWidth={changeSideBarWidth}
        filterType="opportunity"
        filterHasChanged={filterHasChanged}
      />
      {sideBarWidth == 570 && (
        <div className="backdrop" onClick={changeSideBarWidth}></div>
      )}

      <div className="container-fluid">
        <div className="my-call">
          <div className="my-calls-column">
            <div className="calls-top-pannel">
              <div className="row">
                <div className="col-lg-6">
                  <div className="pannel-heading clearfix">
                    <div className="pannel-heading-icon">
                      <i className="flaticon-incoming-call"></i>
                    </div>
                    <div className="pannel-heading-info">
                      {localStorage.getItem("usecase") ===
                        "Third Party Collection Agency" &&
                      localStorage.getItem("client") === "SP Madrid" ? (
                        <p>Promise To Pay </p>
                      ) : localStorage.getItem("usecase") ===
                        "Banking Telesales" ? (
                        <p>Opportunities </p>
                      ) : (
                        <p>Generated Opportunities </p>
                      )}

                      {localStorage.getItem("usecase") ===
                        "Third Party Collection Agency" &&
                      localStorage.getItem("client") === "SP Madrid" ? (
                        <h3>
                          Settlements <i className="icon-down-arrow-round"></i>
                        </h3>
                      ) : localStorage.getItem("usecase") ===
                        "Banking Telesales" ? (
                        <h3>
                          Opportunities Identified
                          <i className="icon-down-arrow-round"></i>
                        </h3>
                      ) : localStorage.getItem("usecase") ===
                        "Augmenting Digital Reach" ? (
                        <h3>
                          Leads Identified
                          <i className="icon-down-arrow-round"></i>
                        </h3>
                      ) : (
                        <h3>
                          Opportunities{" "}
                          <i className="icon-down-arrow-round"></i>
                        </h3>
                      )}
                    </div>
                  </div>
                  <div className="bradcums">
                    <ul className="clearfix">
                      <li>
                        {" "}
                        <i className="fa fa-circle" aria-hidden="true"></i>{" "}
                        {leadList && leadList.length} Items
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="call-table">
            {error && (
              <tr style={{ textAlignVertical: "center", textAlign: "center" }}>
                <td colSpan="6">
                  <p style={{ fontSize: "25px", color: "#FF8520" }}>
                    {error === "Connection Error" && <RiSignalWifiErrorFill />}
                    {error !== "Connection Error" && <BiError />}
                    {error}
                  </p>
                </td>
              </tr>
            )}
            {isPending ? (
              <div className=" flex justify-center">
                <ClipLoader color="#2056FF" size="50px" />
              </div>
            ) : (
              <table className="ss">
                {localStorage.getItem("usecase") ==
                "Third Party Collection Agency" ? (
                  <thead>
                    <tr>
                      <th>
                        <p>Date</p>
                      </th>
                      <th>
                        <p>Customer</p>
                      </th>
                      <th>
                        <p>Call ID</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p> Sentiments</p>
                      </th>

                      <th>
                        <p>Intent</p>
                      </th>
                      <th className=" whitespace-nowrap">
                        <p>Agent Recommendation</p>
                      </th>
                      <th className=" whitespace-nowrap">
                        <p>Dataklout Recommendation</p>
                      </th>
                    </tr>
                  </thead>
                ) : localStorage.getItem("usecase") === "Banking Telesales" ? (
                  <thead>
                    <tr>
                      <th>
                        <p>Call Date</p>
                      </th>
                      <th>
                        <p>Prospect</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p>Call Category </p>
                      </th>
                      <th>
                        <p>Sentiments</p>
                      </th>
                      <th>
                        <p>Intent</p>
                      </th>
                      <th>
                        <p>Lead Score</p>
                      </th>
                      {localStorage.getItem("role") == "Master" ? (
                        <th>
                          <p>Agent</p>
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                ) : localStorage.getItem("usecase") ===
                  "Augmenting Digital Reach" ? (
                  <thead>
                    <tr>
                      <th>
                        <p>Customer ID</p>
                      </th>
                      <th>
                        <p>Call Date</p>
                      </th>
                      <th>
                        <p>Source</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p>Intent</p>
                      </th>

                      <th>
                        <p>Lead Status</p>
                      </th>
                      <th>
                        <p>Action</p>
                      </th>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      <th>
                        <p>Call Date</p>
                      </th>
                      <th>
                        <p>Customer</p>
                      </th>
                      <th>
                        <p>Product</p>
                      </th>
                      <th>
                        <p>Call Type</p>
                      </th>
                      <th>
                        <p>Sentiments</p>
                      </th>
                      <th>
                        <p>Intent</p>
                      </th>
                    </tr>
                  </thead>
                )}

                {localStorage.getItem("usecase") ==
                "Third Party Collection Agency" ? (
                  <tbody>
                    {leadList &&
                      leadList.map((lead) => (
                        <tr
                          key={lead._id}
                          onClick={() =>
                            history.push(
                              `/call/${lead._id}/opportunity-details`
                            )
                          }
                        >
                          <td>
                            <div className="select-check">{lead._date}</div>
                          </td>
                          <td>{lead._customer}</td>
                          <td>{lead._product_name}</td>
                          <td>{lead._status}</td>
                          <td>
                            {lead._sentiment > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                          </td>
                          <td>
                            {lead._intent > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                          </td>
                          <td>Settlement</td>
                          <td>Denial</td>
                        </tr>
                      ))}
                  </tbody>
                ) : localStorage.getItem("usecase") === "Banking Telesales" ? (
                  <tbody>
                    {leadList &&
                      leadList.map((lead) => (
                        <tr
                          key={lead._id}
                          onClick={() =>
                            history.push(
                              `/call/${lead._id}/opportunity-details`
                            )
                          }
                          className="cursor-pointer"
                        >
                          <td>
                            <div className="select-check">{lead._date}</div>
                          </td>
                          <td>{lead._customer}</td>
                          <td>{lead._product_name}</td>
                          <td>{lead._status}</td>
                          <td>
                            {lead._sentiment > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                          </td>
                          <td>
                            {lead._intent > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                          </td>
                          <td>
                            {" "}
                            24
                            {/* <div className="select-pop-up">
                              <div className="dropdown">
                                <button
                                  className="btn btn-primary dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <i className="icon-down-arrow-round"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a href="#">
                                      Category&nbsp; : {lead._category}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">Agent&nbsp; : {lead._agent}</a>
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                          </td>
                          {localStorage.getItem("role") == "Master" ? (
                            <td>{lead._agent}</td>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))}
                  </tbody>
                ) : localStorage.getItem("usecase") ===
                  "Augmenting Digital Reach" ? (
                  <tbody>
                    {leadList &&
                      leadList.map((lead) => (
                        <tr
                          key={lead._id}
                          onClick={() =>
                            history.push(
                              `/call/${lead._id}/opportunity-details`
                            )
                          }
                        >
                          <td>
                            <div className="select-check">
                              {lead?._id.slice(0, 6)}
                            </div>
                          </td>
                          <td>{lead?._date}</td>
                          <td>{lead?._source}</td>
                          <td>{lead?._product_name}</td>

                          <td>
                            {lead?._intent > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                          </td>
                          <td>{lead?._status}</td>

                          <td>{lead?._action || "Send Mail"}</td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    {leadList &&
                      leadList.map((lead) => (
                        <tr
                          key={lead._id}
                          onClick={() =>
                            history.push(
                              `/call/${lead._id}/opportunity-details`
                            )
                          }
                        >
                          <td>
                            <div className="select-check">{lead._date}</div>
                          </td>
                          <td>{lead._customer}</td>
                          <td>{lead._product_name}</td>
                          <td>{lead._status}</td>
                          <td>
                            {lead._sentiment > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                          </td>
                          <td>
                            {lead._intent > 0 ? (
                              <p className="red" style={{ color: "green" }}>
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            ) : (
                              <p className="red" style={{ color: "red" }}>
                                <i
                                  className="fa fa-minus-circle"
                                  aria-hidden="true"
                                />
                              </p>
                            )}
                            <div className="select-pop-up">
                              <div className="dropdown">
                                <button
                                  className="btn btn-primary dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <i className="icon-down-arrow-round"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a href="#">
                                      Category&nbsp; : {lead._category}
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">Agent&nbsp; : {lead._agent}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LeadList;
