import React from "react";
import image from "../src/assets/images/np.png";
import { useHistory, useParams } from "react-router";

function PageNotFound() {
  const history = useHistory();
  console.log(history);
  return (
    <div
      className=" flex justify-center relative h-[100vh]  items-center"
      style={{ height: "100vh" }}
    >
      <img
        src={image}
        alt="Page Not Found"
        style={{ height: "600px", width: "600px" }}
      />

      <button
        className="absolute px-4 py-2  rounded-xl font-semibold hover:bg-orange-500 hover:text-white"
        style={{ border: "1px solid lightgray", bottom: "30px" }}
        onClick={() => history.goBack()}
      >
        Return to Previous page
      </button>
    </div>
  );
}

export default PageNotFound;
