import { Link } from "react-router-dom";
import "./../reports/report-nav.css";
import { BsCollection } from "react-icons/bs";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { useState } from "react";
import { useEffect } from "react";
const ReportNav = () => {
  var url = window.location.href;
  url = url.replace(/^.*\/\/[^\/]+/, "");
  const [open, setOpen] = useState(false);
  const [forex, setForex] = useState(false);
  const [openaudit, setOpenaudit] = useState(false);

  useEffect(() => {
    if (
      url.includes("criticalBusiness") ||
      url.includes("criticalcustomer") ||
      url.includes("criticalregulatory") ||
      url.includes("noncritical")
    ) {
      setOpenaudit(true);
    }
  }, [openaudit]);

  return (
    <>
      {localStorage.getItem("client") !== "SP Madrid" &&
      localStorage.getItem("client") !== "Maya Bank Collections" ? (
        <div
          className={`side-panel`}
          style={{ height: url === "/reports/dashboard" ? "666px" : "" }}
        >
          <nav className="secondary-menu">
            {localStorage.getItem("client") === "DBS Bank Singapore" ? (
              <ul>
                <li
                  className=" list-none"
                  style={{
                    marginLeft: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <i
                    className={`${
                      !open ? "fa fa-arrow-right" : "fa fa-arrow-right click"
                    } text-3xl`}
                    aria-hidden="true"
                  ></i>

                  <a style={{ fontSize: "16px", fontWeight: "bold" }}>Trade</a>
                </li>
                {open && (
                  <ul style={{ marginLeft: "20px", transition: "ease-in" }}>
                    <li className={url === "/reports/forex" ? "active" : ""}>
                      <Link to="/reports/forex">
                        <i class="fa fa-money" aria-hidden="true"></i>
                        <a style={{ fontSize: "14px" }}>Forex</a>
                      </Link>
                    </li>
                    <li
                      className={
                        url === "/reports/equity" ? "active" : "disabled"
                      }
                    >
                      <Link to="/reports/equity">
                        <i class="fa fa-line-chart" aria-hidden="true"></i>
                        <a style={{ fontSize: "14px" }}>Equity</a>
                      </Link>
                    </li>
                    <li
                      className={
                        url === "/reports/mutual" ? "active" : "disabled"
                      }
                    >
                      <Link to="/reports/mutual">
                        <i class="fa fa-users" aria-hidden="true"></i>{" "}
                        <a style={{ fontSize: "14px" }}>Mutual Funds</a>
                      </Link>
                    </li>
                  </ul>
                )}
                {localStorage.getItem("role") === "Master" ? (
                  <li
                    className={
                      url === "/reports/agent-scorecard" ? "active" : ""
                    }
                  >
                    <Link to="/reports/agent-scorecard">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-journal-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                      </svg>
                      <a style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Agent Scorecard
                      </a>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            ) : (
              ""
            )}

            {localStorage.getItem("usecase") === "Complaints Management" ? (
              <ul>
                <li
                  className={url === "/reports/service-request" ? "active" : ""}
                >
                  <div className=" flex items-center">
                    <Link to="/reports/service-request">
                      <i className="fa fa-superpowers" aria-hidden="true"></i>
                      <a style={{ fontSize: "14px" }}> Incoming Complaints </a>
                    </Link>
                  </div>
                </li>
                <li
                  className={url === "/reports/customer-intent" ? "active" : ""}
                >
                  <Link to="/reports/customer-intent">
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Intent Trend</a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/product-intent" ? "active" : ""}
                >
                  <Link to="/reports/product-intent">
                    <i className="flaticon-analytics"></i>
                    <a style={{ fontSize: "14px" }}>Sentiments Trends</a>
                  </Link>
                </li>

                {/* for dbs bank dropdown  */}

                <li
                  className={url === "/reports/cx-score-trend" ? "active" : ""}
                >
                  <Link to="/reports/cx-score-trend">
                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>
                      Complaints Intent Trends{" "}
                    </a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/manager-review" ? "active" : ""}
                >
                  <Link to="/reports/manager-review">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Manager Review</a>
                  </Link>
                </li>

                <li className={url === "/reports/opportunity" ? "active" : ""}>
                  <Link to="#">
                    <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Reopened by DataKlout</a>
                  </Link>
                </li>

                {localStorage.getItem("collection_module") === "true" && (
                  <li className={url === "/reports/collection" ? "active" : ""}>
                    <Link to="#">
                      <span className=" flex items-center">
                        <BsCollection />

                        <a style={{ fontSize: "14px" }}>Customer Survey</a>
                      </span>
                    </Link>
                  </li>
                )}

                <li className={url === "/reports/performance" ? "active" : ""}>
                  <Link to="#">
                    <span className=" flex items-center">
                      <VscActivateBreakpoints />

                      <a style={{ fontSize: "14px" }}>Outlier Case Analysis</a>
                    </span>
                  </Link>
                </li>

                {localStorage.getItem("stock_broking_module") === "true" && (
                  <li className={url === "/reports/compliance" ? "active" : ""}>
                    <Link to="/reports/compliance">
                      <VscActivateBreakpoints />
                      <a style={{ fontSize: "14px" }}>Compliance </a>
                    </Link>
                  </li>
                )}
              </ul>
            ) : localStorage.getItem("client") !== "DBS Bank Singapore" &&
              localStorage.getItem("usecase") !==
                "Insurance - PCVC Verification" ? (
              <ul>
                {localStorage.getItem("usecase") !== "Banking" &&
                localStorage.getItem("usecase") !== "Banking Collections" &&
                localStorage.getItem("usecase") !== "Insurance" ? (
                  <li
                    className={
                      url === "/reports/reports-dashboard" ? "active" : ""
                    }
                  >
                    <Link to="/reports/reports-dashboard">
                      <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                      <a style={{ fontSize: "14px" }}>Report</a>
                    </Link>
                  </li>
                ) : (
                  ""
                )}

                <li
                  className={url === "/reports/service-request" ? "active" : ""}
                >
                  <Link to="/reports/service-request">
                    <i className="fa fa-superpowers" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Service Request</a>
                  </Link>
                </li>
                {localStorage.getItem("role").includes("Master") ? (
                  <li
                    className={
                      url === "/reports/agent-scorecard" ? "active" : ""
                    }
                  >
                    <Link to="/reports/agent-scorecard">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-journal-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                      </svg>
                      <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li
                  className={url === "/reports/customer-intent" ? "active" : ""}
                >
                  <Link to="/reports/customer-intent">
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}> Customer Intent</a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/product-intent" ? "active" : ""}
                >
                  <Link to="/reports/product-intent">
                    <i className="flaticon-analytics"></i>
                    <a style={{ fontSize: "14px" }}>Product by Intent</a>
                  </Link>
                </li>

                {/* for dbs bank dropdown  */}

                <li
                  className={url === "/reports/cx-score-trend" ? "active" : ""}
                >
                  <Link to="/reports/cx-score-trend">
                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>CX Score Trend</a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/manager-review" ? "active" : ""}
                >
                  <Link to="/reports/manager-review">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Manager Review</a>
                  </Link>
                </li>
                <li className={url === "/reports/opportunity" ? "active" : ""}>
                  <Link to="/reports/opportunity">
                    <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                    {localStorage.getItem("usecase") ===
                    "Third Party Collection Agency" ? (
                      <a style={{ fontSize: "14px" }}>Promise To Pay </a>
                    ) : (
                      <a style={{ fontSize: "14px" }}>Opportunities</a>
                    )}
                  </Link>
                </li>

                {localStorage.getItem("collection_module") === "true" && (
                  <li className={url === "/reports/collection" ? "active" : ""}>
                    <Link to="/reports/collection">
                      <span className=" flex items-center">
                        <BsCollection />

                        <a style={{ fontSize: "14px" }}>Collection</a>
                      </span>
                    </Link>
                  </li>
                )}
                {localStorage.getItem("collection_module") === "true" && (
                  <li
                    className={url === "/reports/performance" ? "active" : ""}
                  >
                    <Link to="/reports/performance">
                      <span className=" flex items-center">
                        <VscActivateBreakpoints />

                        <a style={{ fontSize: "14px" }}>Performance</a>
                      </span>
                    </Link>
                  </li>
                )}
                {localStorage.getItem("stock_broking_module") === "true" && (
                  <li className={url === "/reports/compliance" ? "active" : ""}>
                    <Link to="/reports/compliance">
                      <VscActivateBreakpoints />
                      <a style={{ fontSize: "14px" }}>Compliance </a>
                    </Link>
                  </li>
                )}
              </ul>
            ) : localStorage.getItem("usecase") ===
              "Insurance - PCVC Verification" ? (
              <div className="">
                <span
                  className={`flex items-center sec-c flex-row ${
                    url === "/reports/dashboard" ? "active " : ""
                  }
                `}
                >
                  {" "}
                  <Link to="/reports/dashboard" className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill={url === "/reports/dashboard" ? "white" : "black"}
                      class="bi bi-card-checklist"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                      <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
                    </svg>

                    <span
                      className={`font-medium ml-6 ${
                        url === "/reports/dashboard"
                          ? "text-white"
                          : " text-black"
                      }`}
                      style={{ fontSize: "16px" }}
                    >
                      Dashboard
                    </span>
                  </Link>
                </span>
                <div className=" flex flex-col mt-2">
                  <span
                    className={`flex items-center sec-c  cursor-pointer${
                      url === "" ? "active " : ""
                    }
                `}
                    onClick={() => setOpenaudit(true)}
                  >
                    <i
                      className={` opacity-70 ${
                        !openaudit
                          ? "fa fa-arrow-right"
                          : "fa fa-arrow-right click"
                      }`}
                      aria-hidden="true"
                    ></i>

                    <span
                      className={`font-medium ml-6 text-black ${
                        url === "/reports/dashboard" ? "" : ""
                      }`}
                      style={{ fontSize: "16px" }}
                    >
                      Audit
                    </span>
                  </span>
                  {openaudit && (
                    <div className=" flex py- px-8 flex-col">
                      <span
                        className={`flex items-center sec-c ${
                          url === "/reports/criticalBusiness" ? "active " : ""
                        }
                `}
                      >
                        <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                        <Link to="/reports/criticalBusiness">
                          <span
                            className={`font-medium ml-6  ${
                              url === "/reports/criticalBusiness"
                                ? "text-white"
                                : "text-black"
                            }`}
                            style={{ fontSize: "16px" }}
                          >
                            Business
                          </span>
                        </Link>
                      </span>
                      <span
                        className={`flex items-center sec-c ${
                          url === "/reports/criticalcustomer" ? "active " : ""
                        }
                `}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                        </svg>
                        <Link to="/reports/criticalcustomer">
                          <span
                            className={`font-medium ml-6  ${
                              url === "/reports/criticalcustomer"
                                ? "text-white"
                                : "text-black"
                            }`}
                            style={{ fontSize: "16px" }}
                          >
                            Customer
                          </span>
                        </Link>
                      </span>
                      <span
                        className={`flex items-center sec-c ${
                          url === "/reports/criticalregulatory" ? "active " : ""
                        }
                `}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-file-ruled"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v4h10V2a1 1 0 0 0-1-1zm9 6H6v2h7zm0 3H6v2h7zm0 3H6v2h6a1 1 0 0 0 1-1zm-8 2v-2H3v1a1 1 0 0 0 1 1zm-2-3h2v-2H3zm0-3h2V7H3z" />
                        </svg>
                        <Link to="/reports/criticalregulatory">
                          <span
                            className={`font-medium ml-6  ${
                              url === "/reports/criticalregulatory"
                                ? "text-white"
                                : "text-black"
                            }`}
                            style={{ fontSize: "16px" }}
                          >
                            Regulatory{" "}
                          </span>
                        </Link>
                      </span>
                      <span
                        className={`flex items-center sec-c ${
                          url === "/reports/noncritical" ? "active " : ""
                        }
                `}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-card-checklist"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                          <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
                        </svg>
                        <Link to="/reports/noncritical">
                          <span
                            className={`font-medium ml-6  ${
                              url === "/reports/noncritical"
                                ? "text-white"
                                : "text-black"
                            }`}
                            style={{ fontSize: "16px" }}
                          >
                            Non Critical
                          </span>
                        </Link>
                      </span>
                    </div>
                  )}
                </div>
                <span
                  className={`flex items-center sec-c mt-2 ${
                    url === "/reports/agent-scorecard" ? "active " : ""
                  }
                `}
                >
                  <Link to="/reports/agent-scorecard">
                    <i
                      className="fa fa-superpowers"
                      aria-hidden="true"
                      // style={{color:url === "/reports/agent-scorecard ?"":""}}
                      style={{
                        color:
                          url === "/reports/agent-scorecard"
                            ? "white"
                            : "black",
                      }}
                    ></i>
                    <span
                      className={`font-medium ml-6  ${
                        url === "/reports/agent-scorecard"
                          ? "text-white"
                          : "text-black"
                      }`}
                      style={{ fontSize: "16px" }}
                    >
                      Agent Scorecard
                    </span>
                  </Link>
                </span>
              </div>
            ) : (
              ""
            )}
          </nav>
        </div>
      ) : localStorage.getItem("client") === "Maya Bank Collections" ? (
        <div
          className={`side-panel`}
          style={{ height: url === "/reports/dashboard" ? "666px" : "" }}
        >
          <nav className="secondary-menu  marker:bg-none">
            <ul>
              <li className={url === "/reports" ? "active" : ""}>
                <Link to="/reports/promise-to-pay">
                  <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                  <a style={{ fontSize: "14px" }}>Report</a>
                </Link>
              </li>
              {localStorage.getItem("role") === "Master" ? (
                <li
                  className={url === "/reports/agent-scorecard" ? "active" : ""}
                >
                  <Link to="/reports/agent-scorecard">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-journal-text"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                    </svg>
                    <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
      ) : (
        <div
          className={`side-panel`}
          style={{ height: url === "/reports/dashboard" ? "666px" : "" }}
        >
          <nav className="secondary-menu  marker:bg-none">
            <ul>
              <li className={url === "/reports/promise-to-pay" ? "active" : ""}>
                <Link to="/reports/promise-to-pay">
                  <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                  <a style={{ fontSize: "14px" }}>Promise To Pay </a>
                </Link>
              </li>
              <li className={url === "/reports/collection" ? "active" : ""}>
                <Link to="/reports/collection">
                  <span className=" flex items-center">
                    <BsCollection />

                    <a style={{ fontSize: "14px" }}>Collection</a>
                  </span>
                </Link>
              </li>
              {localStorage.getItem("role") === "Master" ? (
                <li
                  className={url === "/reports/agent-scorecard" ? "active" : ""}
                >
                  <Link to="/reports/agent-scorecard">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-journal-text"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                    </svg>
                    <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default ReportNav;
