import { useEffect, useState } from "react";
import Service from "./../webservice/http";
import Modal from "react-modal";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { RiChatVoiceLine } from "react-icons/ri";
import AddNewTextarea from "../addnewtextarea";
// import styles from '.'../quality.module.css'

const ScriptSetup = () => {
  const services = new Service();
  const [scriptCategory, setScriptCategory] = useState("");
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [active, setActive] = useState("Script");
  const [scriptbox, setScriptbox] = useState(true);
  const [phrasebox, setPhrasebox] = useState(false);
  const [showScript, setShowScript] = useState(true);
  const [showphrase, setShowphrase] = useState(false);
  const [showAddBox, setShowAddBox] = useState(false);
  const [id, setId] = useState("");

  const [product, setProduct] = useState("");
  const [productType, setProductType] = useState("");
  const [supportingInfo, setSupportingInfo] = useState();

  function fetchSupportingInfo() {
    services.get("api/call/new_call/").then((res) => setSupportingInfo(res));
  }

  // console.log(supportingInfo);

  useEffect(() => {
    fetchSupportingInfo();
  }, []);

  /**
   * Fetch script category data
   */
  function fetchCategoryData() {
    setPhraseList(null);
    services.get(`api/call_quality/phrase_category/`).then((res) => {
      //   console.log("category", res);
      if (res == "TypeError: Failed to fetch") {
        // console.log("failed to fetch user");
      } else {
        try {
          setScriptCategory(res);
          if (res[0].name !== "Full Script") {
            setSelectedTopTab(res[0].name);
            setSelectedCategoryId(res[0].id);
          } else {
            setSelectedTopTab(res[1].name);
            setSelectedCategoryId(res[1].id);
          }
        } catch {}
      }
    });
  }

  useEffect(() => {
    fetchCategoryData();
    fetchFullScript();
  }, []);

  const [showNewModal, setShowNewModal] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "700px",
      //   height: `${active === "phrase" ? "500px" : ""}`,
    },
  };
  const [newCategoryTitle, setNewCategoryTitle] = useState("");

  /**
   *
   * Create New script category
   */
  const createNewScriptCategory = () => {
    if (newCategoryTitle === "") {
      NotificationManager.error("error", "Please Mention category title");
      return;
    }
    var data = {
      name: newCategoryTitle,
    };
    services.post(`api/call_quality/phrase_category/`, data).then((res) => {
      //   console.log(res);
      if (res == "TypeError: Failed to fetch") {
        // console.log("failed to fetch user");
      } else {
        if (res.message === "success") {
          NotificationManager.success("success", "Created Category");
          setShowNewModal(false);
          setNewCategoryTitle("");
          fetchCategoryData();
        }
      }
    });
  };

  const [selectedTopTab, setSelectedTopTab] = useState("");
  const [selectedCategoryID, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    if (selectedCategoryID != null) fetchPhrases();

    setPhraseList(null);
  }, [selectedCategoryID]);

  const [phraseList, setPhraseList] = useState(null);

  /**
   * Phase Phrases list based on sach script category
   */
  function fetchPhrases() {
    services
      .get(`api/call_quality/phrase_category/${selectedCategoryID}/phrases/`)
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          // console.log("failed to fetch user");
        } else {
          setPhraseList(res);
        }
      });
  }

  function changeCategory(name, id) {
    setSelectedTopTab(name);
    setSelectedCategoryId(id);
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [addScript, setAddScript] = useState("");

  /**
   * Delete script category
   */
  const deleteCategory = () => {
    services
      .delete(`api/call_quality/phrase_category/${selectedCategoryID}/`)
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          // console.log("failed to fetch user");
        } else {
          setShowDeleteModal(false);
          NotificationManager.success("success", "Category Deleted");
          fetchCategoryData();
        }
      });
  };
  const [questionid, setquestionid] = useState("");

  const deletequestions = () => {
    services
      .delete(
        `api/call_quality/phrase_category/${selectedCategoryID}/phrases/${questionid}/`
      )
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          // console.log("failed to fetch user");
        } else {
          setQuestionnairedel(false);
          NotificationManager.success("success", "Question Deleted");
          // fetchCategoryData();
          fetchPhrases();
        }
      });
  };

  const [showPhraseAddModal, setShowPhraseAddModal] = useState(false);
  const [newPhrase, setNewPhrase] = useState("");

  /**
   * Insert new phrase
   *
   */
  const createNewPhrase = () => {
    var data = {
      phrase: input,
    };
    services
      .post(
        `api/call_quality/phrase_category/${selectedCategoryID}/phrases/`,
        data
      )
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          // console.log("failed to fetch user");
        } else {
          if (res.message === "success") {
            NotificationManager.success("success", "Added New Phrase");
            setShowPhraseAddModal(false);
            setNewPhrase("");
            fetchPhrases();
          }
        }
      });
    setInput([]);
  };

  const [fullScript, setFullScript] = useState("");
  const [showFullScriptModel, setShowFullScriptModel] = useState(false);

  const [questionnairedel, setQuestionnairedel] = useState(false);

  function fetchFullScript() {
    services.get(`api/call_quality/manage_full_script/`).then((res) => {
      console.log("full", res);
      if (res == "TypeError: Failed to fetch") {
        // console.log("failed to fetch user");
      } else {
        setFullScript(res);
      }
    });
  }

  // console.log(fullScript);
  const updateFullScript = () => {
    let data;

    if (localStorage.getItem("client") === "HDFC Life") {
      data = {
        product: product,
        // productType: productType,
        phrase: addScript,
      };
    } else {
      data = {
        title: scriptTitle,
        phrase: addScript,
      };
    }

    services.post(`api/call_quality/manage_full_script/`, data).then((res) => {
      // console.log(res);
      if (res == "TypeError: Failed to fetch") {
        // console.log("failed to fetch user");
      } else {
        NotificationManager.success("success", "Created New Script");
        setShowFullScriptModel(false);

        fetchFullScript();
      }
    });
  };

  const editScript = function () {
    let data;

    if (localStorage.getItem("client") === "HDFC Life") {
      data = {
        product: product,
        // productType: productType,
        phrase: editfullscript,
      };
    } else {
      data = {
        title: scriptTitle,
        phrase: editfullscript,
      };
    }

    services
      .patch(`api/call_quality/manage_full_script/${id}/`, data)
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          // console.log("failed to fetch user");
        } else {
          NotificationManager.success("success", "Updated Full Script");
          setEdit(false);
          fetchPhrases();
        }
      });
  };

  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };
  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
    setNewPhrase(tags);
  };

  //   console.log(newPhrase);

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const [value, setValue] = useState("fruit");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [isHovered, setIsHovered] = useState(false);
  //   console.log(value);
  const [scriptTitle, setscriptTitle] = useState(" ");

  const [edit, setEdit] = useState(false);
  const [del, setdel] = useState(false);
  const [editfullscript, seteditfullscript] = useState("");

  const handelDelScript = () => {
    services
      .delete(`api/call_quality/manage_full_script/${id}/`)
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          // console.log("failed to fetch user");
        } else {
          setdel(false);
          NotificationManager.success("success", "Category Deleted");
          fetchFullScript();
        }
      });
  };
  const fetcheditdata = () => {
    services.get(`api/call_quality/manage_full_script/${id}/`).then((res) => {
      console.log(res);
      // console.log(res);
      if (res == "TypeError: Failed to fetch") {
        console.log("failed to fetch user");
      } else {
        setProduct(res?._product);
        setProductType(res?._product_Type);
        seteditfullscript(res?._script);
        setscriptTitle(res?._title);
      }
    });
  };

  useEffect(() => {
    fetcheditdata();
    fetchFullScript();
  }, [edit]);

  return (
    <>
      <NotificationContainer />

      <div
        className="call-table"
        style={{ width: "calc(100% - 444px)", padding: "20px 30px" }}
      >
        <div className="my-calls-column">
          <div className="calls-top-pannel">
            <div className="row">
              <div className="col-lg-6">
                <div className="pannel-heading clearfix">
                  <div className="pannel-heading-info">
                    <h3>Setup Call Script </h3>
                  </div>
                </div>
                <div className="bradcums">
                  <ul className="clearfix"></ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="pannel-nav clearfix">
                  <ul className="clearfix">
                    {localStorage.getItem("client") !== "HDFC Life" && (
                      <li onClick={() => setShowNewModal(true)}>
                        <a>Manage Questionnaire</a>
                      </li>
                    )}

                    <li onClick={() => setShowFullScriptModel(true)}>
                      <a>Manage Full Script</a>
                    </li>
                  </ul>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
        {localStorage.getItem("client") !== "HDFC Life" && (
          <div className=" mb-8 mt-8 ">
            <span
              className={` px-6 py-2 cursor-pointer  text-3xl ${
                showphrase ? "bg-blue-900 text-white    " : ""
              }`}
              style={{ border: `${!showphrase ? "1px solid lightgray" : ""}` }}
              onClick={() => {
                setShowphrase(!showphrase);
                setShowScript(!showScript);
              }}
            >
              Questionnaire
            </span>

            <span
              className={` px-6 py-2 cursor-pointer   text-3xl  ${
                showScript ? "bg-blue-900 text-white    " : " "
              }`}
              style={{
                border: `${!showScript ? "1px solid lightgray" : ""}`,
              }}
              onClick={() => {
                setShowphrase(!showphrase);
                setShowScript(!showScript);
              }}
            >
              Script
            </span>
          </div>
        )}
        {showScript && (
          <div
            className="p-4 overflow-y-scroll scroll-design"
            style={{ border: "1px solid lightgray", height: "450px" }}
          >
            {fullScript &&
              fullScript?.map((e, i) => (
                <div
                  className={`flex  mt-4 rounded-lg p-8  script-hs  justify-between
       
        `}
                  style={{
                    border: "1px solid lightgray",
                    alignItems: "center",
                  }}
                >
                  {/* <input type="checkbox" /> */}
                  <span className=" pl-4" style={{ paddingRight: "60px" }}>
                    <div
                      className=" flex mb-2 "
                      style={{
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        padding: "10px",
                        background: "#eef2f5",
                      }}
                    >
                      {localStorage.getItem("client") == "HDFC Life" ? (
                        <>
                          <div className=" mr-4 font-bold  text-3xl ">
                            Product Type:{" "}
                            <span className=" font-normal  text-2xl ">
                              {e?._product_Type}
                            </span>
                          </div>{" "}
                          <div className=" font-bold text-3xl ">
                            Product:{" "}
                            <span className=" font-normal  text-2xl ">
                              {e?._product}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className=" mr-4 font-bold  text-3xl ">
                            Title :
                            <span className=" font-normal  text-2xl ">
                              {e?._title}
                            </span>
                          </div>{" "}
                        </>
                      )}
                    </div>
                    <p
                      className=" text-2xl"
                      dangerouslySetInnerHTML={{
                        __html: e?._script.replace(/\n/g, "<br />"),
                      }}
                    />
                  </span>
                  <div className=" flex   items-center">
                    <div
                      className={`px-8 rounded-lg py-3 cursor-pointer `}
                      // style={{ border: "1px solid lightgray" }}
                      style={{ height: "34px" }}
                      key={i}
                      onClick={() => {
                        setEdit(true);
                        setId(e._id);
                      }}
                    >
                      <span className=" hover:text-blue-900">
                        <i
                          class="fa fa-pencil-square-o"
                          aria-hidden="true"
                          style={{ fontSize: "25px" }}
                        ></i>
                      </span>
                    </div>
                    {/* {console.log("eeeeeeeeeee", e)} */}
                    <span
                      className="ml-4 text-3xl cursor-pointer hover:text-red-500"
                      onClick={() => {
                        setId(e._id);
                        setdel(true);
                      }}
                    >
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{ fontSize: "25px" }}
                      ></i>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}

        {showphrase && (
          <div className="view-list">
            <ul class="nav nav-tabs">
              {scriptCategory &&
                scriptCategory.map(
                  (item) =>
                    item.name !== "Full Script" && (
                      <li
                        role="presentation"
                        class={selectedTopTab == item.name ? "active" : ""}
                      >
                        <a
                          onClick={() => changeCategory(item.name, item.id)}
                          style={{ fontSize: "16px", padding: "10px" }}
                        >
                          {item.name}
                          &nbsp;&nbsp;
                          {selectedTopTab == item.name && (
                            <i
                              class="fa fa-times-circle-o"
                              aria-hidden="true"
                              onClick={() => setShowDeleteModal(true)}
                            ></i>
                          )}
                        </a>
                      </li>
                    )
                )}
            </ul>
            {console.log(phraseList)}
            <ul className="clearfix" style={{ paddingTop: "20px" }}>
              <ul style={{ marginLeft: "20px" }}>
                {phraseList &&
                  phraseList.map((item, index) => (
                    <li
                      key={index}
                      style={{ padding: "5px", fontSize: "20px" }}
                      className="list-disc"
                    >
                      {item.phrase
                        .replace(/^\[|\]$/g, "")
                        .split(/\d+\./g)
                        .map((phrasePart, index) => (
                          <>
                            {phrasePart}
                            {index < item.phrase.length - 1}
                          </>
                        ))}
                      &nbsp;
                      <span
                        className="hover:text-red-700"
                        onClick={() => {
                          setquestionid(item.id);
                          setQuestionnairedel(true);
                        }}
                      >
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                      </span>
                    </li>
                  ))}
              </ul>
              <div style={{ paddingTop: "20px" }}>
                {scriptCategory.length >= 1 ? (
                  <a onClick={() => setShowPhraseAddModal(true)}>
                    {" "}
                    <p className="" style={{ fontSize: "16px" }}>
                      Add questionnaire points{" "}
                    </p>
                    <AiOutlinePlus size="30" color="green" />
                  </a>
                ) : (
                  <a onClick={() => setShowNewModal(true)}>
                    {" "}
                    <p className="" style={{ fontSize: "16px" }}>
                      Add questionnaire category{" "}
                    </p>
                    <AiOutlinePlus size="30" color="green" />
                  </a>
                )}
              </div>
            </ul>
          </div>
        )}
      </div>

      <Modal
        isOpen={showNewModal}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>New Questionnaire Category </h3>
            </div>
          </div>

          <hr />
          <div className="my-calls-form ">
            <div className="row">
              <div>
                <div className="form-col clearfix">
                  <label>Title </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Questionnaire Category title..."
                    value={newCategoryTitle}
                    onChange={(e) => setNewCategoryTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="popup-footer">
              <>
                <button
                  className="btn"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={() => setShowNewModal(false)}
                >
                  {" "}
                  Close{" "}
                </button>
                <button
                  className="btn Save"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={createNewScriptCategory}
                >
                  {" "}
                  Create{" "}
                </button>
              </>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="errorColor"></p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>Are you sure, want to delete this Category </h3>
            </div>
          </div>

          <hr />
          <div className="my-calls-form">
            <div className="row">
              <div>
                <div className="form-col clearfix">
                  <label>Confirm Delete {selectedTopTab} </label>
                  <br />
                </div>
              </div>
            </div>

            <hr />

            <div className="popup-footer">
              <>
                <button
                  className="btn"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={() => setShowDeleteModal(false)}
                >
                  {" "}
                  Close{" "}
                </button>
                <button
                  className="btn Save"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={deleteCategory}
                >
                  {" "}
                  Confirm{" "}
                </button>
              </>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="errorColor"></p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* question del modal  */}
      <Modal
        isOpen={questionnairedel}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>Are you sure, want to delete this Question ? </h3>
            </div>
          </div>
          <div className="my-calls-form" style={{ padding: "0px" }}>
            <div className="popup-footer">
              <>
                <button
                  className="btn"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={() => setQuestionnairedel(false)}
                >
                  {" "}
                  Close{" "}
                </button>
                <button
                  className="btn Save"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={deletequestions}
                >
                  {" "}
                  Confirm{" "}
                </button>
              </>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="errorColor"></p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showPhraseAddModal}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>Add New Questionnaire</h3>
            </div>
          </div>

          <hr />
          <div className="my-calls-form">
            <div className="row">
              <div>
                <div className="form-col clearfix">
                  <label>Questionnaire points</label>
                  <br />
                  {/* <input
                    type="text"
                    style={{ width: "700px" }}
                    placeholder="Enter Script Category title..."
                    value={newPhrase}
                    onChange={(e) => setNewPhrase(e.target.value)}
                  /> */}
                  <div className="container" style={{ height: "auto" }}>
                    {tags.map((tag, index) => (
                      <div className="tag">
                        {tag}
                        <button onClick={() => deleteTag(index)}>x</button>
                      </div>
                    ))}
                    {/* {console.log(tags)} */}
                    <input
                      value={input}
                      placeholder="Enter Questionnaire points..."
                      style={{
                        width: "500px",
                        border: "none",
                        outline: "none",
                      }}
                      onChange={(e) => setInput(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="popup-footer">
              <>
                <button
                  className="btn"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={() => {
                    setTags([]);
                    setShowPhraseAddModal(false);
                  }}
                >
                  {" "}
                  Close{" "}
                </button>
                <button
                  className="btn Save"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={createNewPhrase}
                >
                  {" "}
                  Create{" "}
                </button>
              </>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="errorColor"></p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showFullScriptModel}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>Add Full Script </h3>
            </div>
          </div>

          <hr />
          {localStorage.getItem("client") == "HDFC Life" ? (
            <div className="my-calls-form">
              <div className="row">
                <div className=" flex justify-between">
                  <div className="form-col clearfix w-1/2 mr-4 ">
                    <label>Product Type </label>
                    <br />
                    <select onChange={(e) => setProductType(e.target.value)}>
                      <option value=""></option>
                      {supportingInfo?.call_type.map((callType) => (
                        <option value={callType.title}>
                          {callType.title
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-col clearfix w-1/2 ">
                    <label>Product </label>
                    <br />
                    <select onChange={(e) => setProduct(e.target.value)}>
                      <option value=""></option>
                      {supportingInfo?.product.map((product) => (
                        <option value={product.title}>
                          {product.title
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <div className="form-col clearfix">
                    <label>Script </label>
                    <br />
                    <textarea
                      type="text"
                      style={{ height: "200px" }}
                      placeholder="Enter full Script"
                      // value={fullScript}
                      onChange={(e) => setAddScript(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>

              <hr />

              <div className="popup-footer">
                <>
                  <button
                    className="btn"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={() => setShowFullScriptModel(false)}
                  >
                    {" "}
                    Close{" "}
                  </button>
                  <button
                    className="btn Save"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={updateFullScript}
                  >
                    {" "}
                    Add{" "}
                  </button>
                </>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="errorColor"></p>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-calls-form">
              <div className="row">
                <div>
                  <div className="form-col clearfix">
                    <label>Title </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Script title..."
                      value={scriptTitle}
                      onChange={(e) => setscriptTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="form-col clearfix">
                    <label>Script </label>
                    <br />
                    <textarea
                      type="text"
                      style={{ height: "200px" }}
                      placeholder="Enter full Script"
                      // value={fullScript}
                      onChange={(e) => setAddScript(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>

              <hr />

              <div className="popup-footer">
                <>
                  <button
                    className="btn"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={() => setShowFullScriptModel(false)}
                  >
                    {" "}
                    Close{" "}
                  </button>
                  <button
                    className="btn Save"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={updateFullScript}
                  >
                    {" "}
                    Update{" "}
                  </button>
                </>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="errorColor"></p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={edit}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>Edit Script</h3>
            </div>
          </div>

          <hr />
          {localStorage.getItem("client") == "HDFC Life" ? (
            <div className="my-calls-form">
              <div className="row">
                <div className=" flex justify-between">
                  <div className="form-col clearfix w-1/2 mr-4 ">
                    <label>Product Type </label>
                    <br />
                    <select
                      onChange={(e) => setProductType(e.target.value)}
                      value={productType}
                    >
                      <option value=""></option>
                      {supportingInfo?.call_type.map((callType) => (
                        <option value={callType.title} selected>
                          {callType.title
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-col clearfix w-1/2 ">
                    <label>Product </label>
                    <br />
                    <select
                      onChange={(e) => setProduct(e.target.value)}
                      value={product}
                    >
                      <option value=""></option>
                      {supportingInfo?.product.map((product) => (
                        <option value={product.title} selected>
                          {product.title
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <div className="form-col clearfix">
                    <label>Script </label>
                    <br />
                    <textarea
                      type="text"
                      style={{ height: "200px" }}
                      placeholder="Enter full Script"
                      value={editfullscript}
                      onChange={(e) => seteditfullscript(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>

              <hr />

              <div className="popup-footer">
                <>
                  <button
                    className="btn"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={() => setEdit(false)}
                  >
                    {" "}
                    Close{" "}
                  </button>
                  <button
                    className="btn Save"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={editScript}
                  >
                    {" "}
                    Update{" "}
                  </button>
                </>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="errorColor"></p>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-calls-form">
              <div className="row">
                <div>
                  <div className="form-col clearfix">
                    <label>Title </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Script title..."
                      value={scriptTitle}
                      onChange={(e) => setscriptTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="form-col clearfix">
                    <label>Script </label>
                    <br />
                    <textarea
                      type="text"
                      style={{ height: "200px" }}
                      placeholder="Enter full Script"
                      value={editfullscript}
                      onChange={(e) => seteditfullscript(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>

              <hr />

              <div className="popup-footer">
                <>
                  <button
                    className="btn"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={() => setEdit(false)}
                  >
                    {" "}
                    Close{" "}
                  </button>
                  <button
                    className="btn Save"
                    style={{ padding: "9px" }}
                    type="button"
                    onClick={() => {
                      setEdit(false);
                      editScript();
                    }}
                  >
                    {" "}
                    Update{" "}
                  </button>
                </>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="errorColor"></p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={del}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className="col-md-12">
          <div className="pannel-heading clearfix">
            <div className="pannel-heading-info">
              <h3>Are you sure, want to delete this Script ?</h3>
            </div>
          </div>

          {/* <hr /> */}
          <div className="my-calls-form">
            <div className="popup-footer">
              <>
                <button
                  className="btn"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={() => setdel(false)}
                >
                  {" "}
                  Close{" "}
                </button>
                <button
                  className="btn Save"
                  style={{ padding: "9px" }}
                  type="button"
                  onClick={handelDelScript}
                >
                  {" "}
                  Confirm{" "}
                </button>
              </>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="errorColor"></p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScriptSetup;
