const data = {
  data: [
    {
      Date: "Forex",
      Agent: "Ravi",
      Product: "Forex",
      Type: "Service Request",
      NRICnumber: "yes",
      AccountNumber: "No",
      DOB: "",
      PaymentHistory: "",
      EmailAddress: "yes",
    },
    // {
    //   Date: "18-12-2022",
    //   Agent: "Alex",
    //   Product: "Equity",
    //   Type: "Opportunity",
    //   NRICnumber: "yes",
    //   AccountNumber: "yes",
    //   DOB: "no",
    //   PaymentHistory: "no",
    //   EmailAddress: "no",
    // },
    // {
    //   Date: "18-08-2022",
    //   Agent: "David",
    //   Product: "Equity",
    //   Type: "Collection",
    //   NRICnumber: "yes",
    //   AccountNumber: "yes",
    //   DOB: "yes",
    //   PaymentHistory: "yes",
    //   EmailAddress: "no",
    // },
  ],
};

export default data;
