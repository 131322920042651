import React from "react";

import NavBar from "../../nav/nav-bar";
import Header from "../../header/header";
import ReportNav from "../report-nav";
import Footer from "../../footer/footer";
import Dashscript from "../../dashscript";
import { useState } from "react";
import { useEffect } from "react";
import { event } from "jquery";
import DatePicker from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import moment from "moment";
import Service from "../../webservice/http";
import { Loader } from "rsuite";
import { debounce } from "lodash";
import axios from "axios";
import { async } from "q";
import ClipLoader from "react-spinners/ClipLoader";

function Outputdatabase() {
  const tableHeadingArray = [
    "Report Generation",
    "Call ",
    "Audit ",
    "NEMP ID",
    "Application No",
    "Call Language",
    " Business",
    " Customer",
    " Regulatory",
    " Non Critical",
    " Call Status",
  ];
  const defaultDate = [
    moment().subtract(1, "months").format("DD-MM-YYYY"),
    moment().format("DD-MM-YYYY"),
  ];

  const services = new Service();

  const [type, setType] = useState("");
  const [tabledata, setTabledata] = useState([]);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState([
    new Date().setMonth(new Date().getMonth() - 1),
    new Date(),
  ]);
  const [range, setRange] = useState("");

  const [Loading, setLoading] = useState(false);

  async function Dashbaorddata() {
    setLoading(true);

    let url = `api/pcvc/dashboard_list/`;

    if (range.length == 2) {
      url = url + `?from_date=${range[0]}&to_date=${range[1]}`;
    }
    if (search) {
      url =
        range.length == 2
          ? url + `&search=${search}`
          : url + `?search=${search}`;
    }
    if (type) {
      url =
        range.length == 2 || search
          ? url + `&call_status=${type}`
          : url + `?call_status=${type}`;
    }

    const res = await services
      .get(url)

      .then((res) => {
        setTabledata(res?.data);
        setLoading(false);
      });
  }

  useEffect(() => {
    Dashbaorddata();
  }, [value, type, range, search]);

  const handleChange = (event) => {
    setType(event.target.value);
  };
  const debouncedSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  const handleDates = (dates) => {
    if (dates) {
      const range = dates.map((date) => {
        return date.format("DD-MM-YYYY");
      });
      setRange(range);
    }
  };

  // console.log(localStorage.getItem("access_token"));
  // const token = ;
  // console.log(token);
  const downloadReport = async function () {
    let downloadUrl = "https://fb.dataklout.com/api/pcvc/export_dashboard/";
    if (range.length == 2) {
      downloadUrl = downloadUrl + `?from_date=${range[0]}&to_date=${range[1]}`;
    }
    if (search) {
      downloadUrl =
        range.length == 2
          ? downloadUrl + `&search=${search}`
          : downloadUrl + `?search=${search}`;
    }
    if (type) {
      downloadUrl =
        range.length == 2 || search
          ? downloadUrl + `&call_status=${type}`
          : downloadUrl + `?call_status=${type}`;
    }

    try {
      const response = await axios.get(downloadUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // console.log(url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dashboard_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />
      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div className="side-panel-with-table clearfix">
              <ReportNav />
              <div className="call-table " style={{ marginBottom: "22px" }}>
                <div
                  className="my-calls-column"
                  style={{ padding: "15px 0px 0px 15px" }}
                >
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Dashboard</h3>
                            <p> Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex justify-end m-3">
                  <DatePicker
                    // defaultValue="Select Date Range"

                    value={value}
                    onChange={(date) => {
                      handleDates(date);
                    }}
                    range={true}
                    maxDate={
                      true
                        ? new Date(
                            new Date().setMonth(new Date().getMonth() + 0)
                          )
                        : null
                    }
                    style={{
                      marginRight: "20px",
                      width: "180px",
                      height: "35px",
                      borderRadius: "15px",
                      background: "#eef2f5",
                      // border: "1px solid black",
                      border: "none",
                      color: "black",
                      fontWeight: "normal",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") {
                        e.preventDefault();
                      }
                    }}
                    format="DD/MM/YYYY"
                    plugins={[
                      <Toolbar
                        key="date-pick-btn"
                        position="bottom"
                        names={{
                          today: "Today",
                          deselect: "Reset",
                          close: "Close",
                        }}
                      />,
                    ]}
                  />
                  <select
                    name=""
                    id="call-type"
                    onChange={handleChange}
                    className=" mr-6"
                  >
                    <option value="" disabled selected hidden>
                      Call Status
                    </option>
                    <option value="">All</option>
                    <option value="fatal">Fatal</option>
                    <option value="non-fatal">Non Fatal</option>
                  </select>
                  <input
                    onChange={(event) => debouncedSearch(event.target.value)}
                    type="text"
                    placeholder="Search..."
                    className="search-tag"
                    style={{
                      // border: "1px solid transparent",
                      border: "none",
                      background: "#eef2f5",
                      borderRadius: "10px",
                      width: "250px",
                      outline: "none",
                      paddingLeft: "8px",
                      height: "35px",
                    }}
                  />
                </div>
                <div className=" flex justify-end m-6">
                  <button
                    className={` text-white px-8 py-4 rounded-xl export-btn tracking-wide ${
                      !tabledata?.length ? " opacity-60" : ""
                    }`}
                    disabled={!tabledata?.length}
                    onClick={downloadReport}
                  >
                    Export
                  </button>
                </div>

                <div className=" overflow-x-scroll">
                  <table
                    className="ss mb-40"
                    style={{ border: "1px solid grey" }}
                  >
                    <tr>
                      <th
                        colSpan="3"
                        className="left-0 bg-white  px-5 py-4  text-center  text-xl font-semibold tracking-wider text-black ss-th "
                        style={{
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        Date
                      </th>
                      <th
                        colSpan="3"
                        style={{
                          borderBottom: "1px solid lightgrey",
                          textAlign: "center",
                        }}
                        // className="left-0 bg-white  px-5 py-4  text-center  text-xl font-semibold uppercase tracking-wider text-black ss-th "
                      >
                        -{/* Other Informations */}
                      </th>
                      <th
                        colSpan="3"
                        style={{
                          borderBottom: "1px solid lightgrey",
                          textAlign: "center",
                          color: "black",
                        }}
                        // className="left-0 bg-white  px-5 py-4  text-center  text-xl font-semibold uppercase tracking-wider text-black ss-th "
                      >
                        {/* Other Informations */}
                        Critical
                      </th>
                      <th
                        colSpan="3"
                        style={{
                          borderBottom: "1px solid lightgrey",
                          textAlign: "center",
                        }}
                        // className="left-0 bg-white  px-5 py-4  text-center  text-xl font-semibold uppercase tracking-wider text-black ss-th "
                      >
                        -{/* Other Informations */}
                      </th>
                    </tr>
                    <tr id="black-line">
                      {tableHeadingArray.map((item, indx) => (
                        <th
                          key={item}
                          className={`
                               left-0 bg-white  border-b-2 border-gray-200 px-5 py-4 text-left  text-xl font-semibold  tracking-wider text-black  
                                 ${indx === 3 ? " sticky-top left-0" : ""}
                               }  `}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>

                    <tbody>
                      {Loading && (
                        <tr>
                          <td colSpan="11" style={{ textAlign: "center" }}>
                            <ClipLoader color="#2056FF" size="50px" />
                          </td>
                        </tr>
                      )}

                      {tabledata.length ? (
                        tabledata.map((e, i) => {
                          return (
                            <tr
                              key={i}
                              // className={`${
                              //   e["Call Status"] === "Fatal"
                              //     ? " bg-red-500"
                              //     : " bg-green-500"
                              // }`}
                              // className=" bg-red-300"
                              // style={{ background: "red" }}
                            >
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs first-letter `}
                              >
                                <div>
                                  <p className="whitespace-no-wrap cursor-pointer text-center text-sky-700">
                                    {e.report_generation_date}
                                  </p>
                                </div>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2 w-40 text-xs`}
                                id="date"
                              >
                                <p className="whitespace-nowrap text-left text-gray-900 ">
                                  {e.call_date}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2   w-40 text-xs`}
                                id="date"
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e.audit_date}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs`}
                              >
                                <p className="whitespace-no-wrap text-left text-gray-900">
                                  {e.nemp_id}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs sticky left-0 `}
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e.application_no}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs whitespace-nowrap`}
                              >
                                <p className="  text-left text-gray-900">
                                  {e.language}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2 w-40 text-xs`}
                              >
                                <p className="whitespace-nowrap text-left capitalize text-gray-900">
                                  {e.critical_to_business}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2 w-40 text-xs`}
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e.critical_to_customer}
                                </p>
                              </td>
                              {/* NEW MOBILE NUMBER  COLOUMN  */}
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs text-center`}
                              >
                                <p className="whitespace-nowrap text-left text-gray-900">
                                  {e.critical_to_regulatory}
                                </p>
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs`}
                                // style={{
                                //   textAlign: e.non_critical ? "left" : "center",
                                // }}
                              >
                                {e.non_critical ? (
                                  <p className="whitespace-nowrap  text-gray-900">
                                    {e.non_critical}
                                  </p>
                                ) : (
                                  <span className="font-bold">-</span>
                                )}
                              </td>
                              <td
                                className={`border-b border-gray-200 px-5 py-2 w-40 text-xs`}
                              >
                                <p
                                  className={`whitespace-nowrap text-left ${
                                    e.call_status === "fatal"
                                      ? " text-red-500  text-2xl font-bold"
                                      : " text-green-700 text-2xl font-bold"
                                  }`}
                                >
                                  {e.call_status[0].toUpperCase() +
                                    e.call_status.slice(1)}
                                </p>
                              </td>
                              {/* <td
                                className={`border-b border-gray-200 px-5 py-2  w-40 text-xs cursor-pointer`}
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : Loading ? (
                        ""
                      ) : (
                        <tr>
                          <td colSpan="11" style={{ textAlign: "center" }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Outputdatabase;
