import { useState, useEffect } from "react";
import Service from "./webservice/http";
import Masonry from "react-masonry-css";
import "./footer/footer.css";

const Notes = () => {
  const [showNotesModel, setShowNotesModel] = useState(false);
  const toggleNotesModel = () => {
    setShowNotesModel(!showNotesModel);
  };

  const services = new Service();
  const [notesData, setNotesData] = useState();
  const [note, setNote] = useState("");
  const [subject, setSubject] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [error, setError] = useState(null);
  const [isPending, setisPending] = useState(false);

  /**
   * Fetch Notes from manage note API
   */
  async function fetchNotes() {
    setError(null);
    try {
      const res = await services.get("/api/notes/manage_notes/");
      // console.log(res, "response");
      setisPending(false);
      if (res === "TypeError: Failed to fetch") {
        setError("Failed to Fetch");
      } else {
        setNotesData(res);
        setError(null);
      }
    } catch (error) {
      setError("Failed to Fetch");
    }
  }

  /**
   * Initiate fetch notes on page load
   */
  useEffect(() => {
    fetchNotes();
  }, []);

  /**
   * Create a new note and post to Manage notes API
   */
  const addNewNote = async () => {
    if (note !== "" && subject !== "") {
      var notesData = {
        note: note,
        subject: subject,
        color: color,
      };
      try {
        const res = await services.post("/api/notes/manage_notes/", notesData);
        // console.log(res);
        if (res === "TypeError: Failed to fetch") {
          setError("Failed to Fetch");
        } else {
          setNote("");
          fetchNotes();
          setSubject("");
        }
      } catch (error) {
        setError("Failed to Fetch");
      }
    }
  };

  /**
   * Perform delete note
   */

  /**
   *update note color and post to API
   */

  return (
    <>
      <footer>
        <div>
          <div className="footer-box" style={{ cursor: "pointer" }}>
            <a onClick={toggleNotesModel}>
              <img
                src="assets/images/notes.png"
                className="img-responsive"
                alt=""
              />
              Notes
            </a>
          </div>
        </div>
      </footer>

      {showNotesModel && (
        <div className="modal my-calls-popup show">
          <div className="backdrop"></div>
          <div className="modal-dialog" role="document">
            <div className="col-lg-4">
              <div
                className="white-box-global ww-caps "
                style={{ minHeight: "0px important!", padding: "8px" }}
              >
                <div
                  className="form-col clearfix"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="pull-right"
                    style={{ cursor: "pointer" }}
                    onClick={toggleNotesModel}
                  >
                    <i
                      className="fa fa-times pull-right"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="white-box-header">
                    <h3>Notes</h3>
                  </div>
                  <div
                    className="col-md-3"
                    style={{
                      width: "auto",
                      marginTop: " -3px",
                      marginBottom: "19px",
                    }}
                  >
                    <label
                      className="notes-lable"
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        letterSpacing: " 0.095em",
                        color: "#737d8b",
                        marginBottom: "5px",
                      }}
                    >
                      Subject
                    </label>

                    <select>
                      <option>Clinic Plus Shampoo</option>
                      <option>Glow & Lovely</option>
                      <option>Kissan Ketchup</option>
                      <option>Skin Care</option>
                      <option>Hair Care</option>
                    </select>
                  </div>

                  <div className="col-md-7" style={{ width: "auto" }}>
                    <label
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        letterSpacing: " 0.095em",
                        color: "#737d8b",
                        marginBottom: "5px",
                      }}
                    >
                      Add Notes
                    </label>
                    <textarea
                      rows={4}
                      cols={50}
                      style={{ height: "140px" }}
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-md-1">
                    <div style={{ marginTop: "27px" }}>
                      <button
                        className="btn Save"
                        style={{
                          background: "#270086",
                          color: "white",
                          padding: "5px 20px",
                          fontWeight: " 500",
                          fontSize: "14px",
                          outline: "none",

                          lineHeight: "118%",
                          textAlign: "center",
                          letterSpacing: "0.045em",
                          color: " #e3e3e3",
                          outline: "none",
                          border: "none",
                          padding: "9px 44px",
                          transition: "all 0.4s ease-in-out",
                        }}
                        onClick={addNewNote}
                      >
                        submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notes;
