import React from "react";
import { useState, useEffect } from "react";

function Viewmore({ setShowNewModal }) {
  const [data, setdata] = useState();
  //   const services = new Service();
  //   console.log(orderId);

  //   function fetchData() {
  //     services.get(`api/product/${orderId}/equity_list/`).then((res) => {
  //       // console.log(res);
  //       setdata(res);
  //     });
  //   }

  return (
    <div className="modal my-calls-popup show">
      <div className="backdrop"></div>
      <div className="modal-dialog " role="document" id="m-pop">
        <div className="my-calls-popup-details">
          <h2>Detailed Report</h2>
          <div className="my-calls-form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Call Date :</label>
                  <span id="p-detail" className=" font-bold">
                    19-04-2024
                  </span>

                  <br />
                </div>
                <div className="form-col clearfix">
                  <label>Audit Date :</label>
                  <span id="p-detail" className=" font-bold">
                    21-04-2024
                  </span>
                </div>
              </div>
              {/* {Name		Company	Buy	Sell	Shares	Price	Amount} */}
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Subscriber Name :</label>
                  <span id="p-detail" className=" font-bold">
                    Albert
                  </span>
                </div>
                <div className="form-col clearfix">
                  <label>Loan Product :</label>
                  <span id="p-detail" className=" font-bold">
                    Car Loan
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Agent :</label>
                  <span id="p-detail" className=" font-bold">
                    John
                  </span>
                </div>

                <div className="form-col clearfix">
                  <label> Call Language :</label>
                  <span id="p-detail" className=" font-bold">
                    Malay English
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Team Lead :</label>
                  <span id="p-detail" className=" font-bold">
                    {" "}
                    Alok
                  </span>
                </div>
                <div className="form-col clearfix">
                  <label> Did the agent provide branding?</label>
                  <span id="p-detail" className=" font-bold">
                    Yes
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Was the agent prepared for the call?</label>
                  <span id="p-detail" className=" font-bold">
                    Yes
                  </span>
                </div>
                <div className="form-col clearfix">
                  <label> Did the agent provide branding? </label>
                  <span id="p-detail" className=" font-bold">
                    No
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label> Did the agent advise call recording? </label>
                  <span id="p-detail" className=" font-bold">
                    Yes
                  </span>
                </div>
                <div className="form-col clearfix">
                  <label> Did the agent avoid overtalking? </label>
                  <span id="p-detail" className=" font-bold">
                    No
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="border"></div>
          <div className="popup-footer">
            <>
              {/* <button className="btn" data-dismiss="modal" type="button">
                {" "}
                Edit
              </button> */}
              {/* <button
              onClick={() => {
                setOpen(false);
              }}
              className="btn"
              data-dismiss="modal"
              type="button"
            >
              Save
            </button> */}
              <button
                onClick={() => {
                  setShowNewModal(false);
                }}
                className={`  px-8 py-4 rounded-xl export-btn tracking-wide mr-4 bg-gray-200 text-black   hover:bg-orange-600 hover:text-white  font-medium`}
                data-dismiss="modal"
                type="button"
                // style={{ borderRadius: "10px" }}
              >
                Close
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewmore;
