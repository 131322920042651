import React from "react";
import { useState, useEffect } from "react";
import Service from "../webservice/http";

function ForexViewmore({ orderId, setOpen }) {
  const [data, setdata] = useState();
  const services = new Service();
  //   console.log(data);

  function fetchData() {
    services.get(`api/product/${orderId}/forex_list/`).then((res) => {
      // console.log(res);
      setdata(res);
    });
  }
  //   console.log(data);
  useEffect(() => {
    fetchData();
  }, [orderId]);

  return (
    <div className="modal my-calls-popup show">
      <div className="backdrop"></div>
      <div className="modal-dialog " role="document" id="m-pop">
        <div className="my-calls-popup-details">
          <h2>Forex Details</h2>
          <div className="my-calls-form">
            <div className="row">
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Name :</label>
                  <span id="p-detail">{data?.name}</span>
                  <br />
                </div>
                <div className="form-col clearfix">
                  <label>Buy :</label>
                  <span id="p-detail">{data?.buy}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Contract Number :</label>
                  <span id="p-detail">{data?.contract_number}</span>
                </div>
                <div className="form-col clearfix">
                  <label>Sell :</label>
                  <span id="p-detail">{data?.sell}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Quantity :</label>
                  <span id="p-detail">{data?.quantity}</span>
                </div>

                <div className="form-col clearfix">
                  <label>Amount :</label>
                  <span id="p-detail">{data?.amount}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-col clearfix">
                  <label>Final Price :</label>
                  <span id="p-detail">{data?.final_price}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="border"></div>
          <div className="popup-footer">
            <>
              {/* <button className="btn" data-dismiss="modal" type="button">
                {" "}
                Edit
              </button> */}
              {/* <button
              onClick={() => {
                setOpen(false);
              }}
              className="btn"
              data-dismiss="modal"
              type="button"
            >
              Save
            </button> */}
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="btn"
                data-dismiss="modal"
                type="button"
              >
                Close
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForexViewmore;
